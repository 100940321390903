import {
	getCountriesApi,
	getFullLanguagesApi,
	getShortLanguagesApi
} from '@/apis/content.js'
export default {
	state: {
		countries: [],
		fullLanguages: [],
		shortLanguages: []
	},
	mutations: {
		initContent(state) {
			Object.keys(state).forEach(key => {
				try {
					state[key] = JSON.parse(localStorage.getItem(key)) || []
				} catch {}
			})
		},
		saveContent(state, {
			type,
			content
		}) {
			localStorage.setItem(type, JSON.stringify(content))
			state[type] = content
		}
	},
	actions: {
		async getFullLanguages({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				getFullLanguagesApi()
					.then(content => {
						commit('saveContent', {
							type: 'fullLanguages',
							content
						})
						resolve()
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		async getShortLanguages({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				getShortLanguagesApi()
					.then(content => {
						commit('saveContent', {
							type: 'shortLanguages',
							content
						})
						resolve(content)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		async getCountries({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				getCountriesApi()
					.then(content => {
						commit('saveContent', {
							type: 'countries',
							content
						})
						resolve()
					})
					.catch(err => {
						reject(err)
					})
			})
		}
	}
}
