import actions from './actions.js'
import getters from './getters.js'
export default {
	namespaced: true,
	state() {
		return {
			_countries: [],
			_fullLanguages: [],
			_shortLanguages: [],
		}
	},
	getters,
	actions
}