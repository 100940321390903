import {
	fetchLTVDataApi,
	exportLTVDataApi
} from '@/apis/dataset.js'

export default {
	async getList(context, {params, trigger}) {
		return fetchLTVDataApi(params, trigger)
	},
	async exportData(context, params) {
		return exportLTVDataApi(params)
	},
}