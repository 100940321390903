export default {
	countries(state) {
		return state._countries
	},
	fullLanguages(state) {
		return state._fullLanguages
	},
	shortLanguages(state) {
		return state._shortLanguages
	}
}