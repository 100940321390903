import {
	newItemApi,
	updateItemApi,
	getListApi,
	activeItemApi,
	activeItemsApi,
	getItemApi
} from '@/apis/google-content-landinglink.js'

export default {
	// UDA模板
	async newItem(context, body) {
		return newItemApi(body)
	},
	async updateItem(context, { id, body }) {
		return updateItemApi(id, body)
	},
	async getList(context, { params, trigger }) {
		return getListApi(params, trigger)
	},
	async activeItem(context, {id, active }) {
		return activeItemApi(id, active)
	},
	async activeItems(context, { ids, active}) {
		return activeItemsApi(ids, active)
	},
	async getItem(context, id) {
		return getItemApi(id)
	}
}