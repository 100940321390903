import {
	getListApi,
	getConfigApi,
	updateConfigApi,
	addSpusApi,
	delProductApi
} from '@/apis/super-test.js'

export default {
	async getList(context, {params, trigger}) {
		return getListApi(params, trigger)
	},
	async getConfig(context, body) {
		return getConfigApi()
	},
	async updateConfig(context, body) {
		return updateConfigApi(body)
	},
	async addSpus(context,  body) {
		return addSpusApi(body)
	},
	async delProduct(context, id) {
		return delProductApi(id)
	}
}