import Vue from 'vue'
import Router from 'vue-router'
import routes from './common/config/router.js'
import {Message} from 'element-ui'
import { encode } from 'js-base64';
Vue.use(Router)


/**
 * 同路径路由异常处理：
 * NavigationDuplicated: Avoided redundant navigation to current location
 * */
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
//获取原型对象上的replace函数
const originalReplace = Router.prototype.replace
//修改原型对象中的replace方法
Router.prototype.replace = function replace(location,onComplete,onAbort) {
   return originalReplace.call(this, location, onComplete,onAbort).catch(err => err)
}

let router = new Router({
	mode:'history',
	routes
})
function lookupPermission(route) {
	try {
		const {parent, meta} = route
		const { permission } = meta
		const foundPermission = permission || lookupPermission(parent)
		if (foundPermission) {
			return foundPermission
		}
	} catch (e) {
		console.info(e)
	}
}
function canAccess(to, permissions, superAdmin) {
	const {matched, meta: { permission, redirect }, fullPath} = to
	// 403 or 404 Disabled for nested router redirect matched
	if (fullPath.startsWith('/error')) {
		return false
	}
	var uncheckedPermission = permission || lookupPermission(matched[matched.length - 1])
	// Block By 403
	if (!superAdmin && uncheckedPermission && permissions.find(v => v === uncheckedPermission) === undefined) {
		const body = {permission, fullPath, timestamp : (new Date()).getTime()}
		const errMsg = encode(JSON.stringify(body))
		return {name: 'forbid', query: {errMsg}}
	}
	// Infer Redirect
	if (redirect) {
		const newPath = redirect.split(',').map(p=> `${fullPath}/${p}`).find(path => {
			const redirectRoute = router.match(path)
			const v = canAccess(redirectRoute, permissions, superAdmin)
			return v === true
		})
		if (newPath) {
			return newPath
		} else {
			const body = {cause: `${fullPath} 嵌套路由权限未配置`, fullPath, timestamp : (new Date()).getTime()}
			const errMsg = encode(JSON.stringify(body))
			return {name: 'forbid', query: {errMsg}}
		}
	}
	return true
}
// 全局前置守卫
router.beforeEach((to, from, next) => {
	var user = undefined
	try {
		user = JSON.parse(localStorage.getItem('USER'))
	} catch (e) {}
	const {matched, fullPath, name} = to
	
	const hasPermission = matched.reduce((lhs, rhs) => {
		const {meta: { permission}} = rhs
		return lhs || permission !== undefined
	}, false)
	if (!user) {
		if (matched[0].name !== 'layouts_login') {
			return next('/login')
		}
		return next()
	}
	if (matched[0].name === 'layouts_login' ) {
		Message.warning('您已登录')
		return next('/')
	}
	// 已登录
	const { permissions = [], superAdmin = false } = user
	if (fullPath.startsWith('/error')) {
		return next()
	}
	next(canAccess(to, permissions, superAdmin))
})

export default router
