export default {
	picInfos() {
		return {
			'SIZE_160_600': {
				top: '160x71',
				end: '160x99'
			},
			'SIZE_300_600': {
				top: '300x68',
				end: '300x109'
			},
			'SIZE_300_250': {
				top: '300x40'
			},
			'SIZE_970_250': {
				end: '196x250'
			}
		}
	}
}