import {
	fetchFeedQueuesApi,
	createFeedApi,
	stopFeedApi,
	batchStopFeedsApi,
	downloadFeedApi
} from '@/apis/feed.js'
export default {
	async getList(context, { params, trigger }) {
		return fetchFeedQueuesApi(params, trigger)
	},
	async newItem(context, body) {
		return createFeedApi(body)
	},
	async deactiveItem(context, id) {
		return stopFeedApi(id)
	},
	async deactiveItems(context, taskIds) {
		return batchStopFeedsApi(taskIds)
	},
	async downloadFeed(context, id) {
		return downloadFeedApi(id)
	}
}