import {
	fetchGoogleProductCategoriesApi,
	fetchFeedContentsApi,
	fetchFeedContentLogsApi,
	updateFeedContentApi,
	fetchChannelsApi
} from '@/apis/feed.js'
export default {
	async getCategories(context, params) {
		return fetchGoogleProductCategoriesApi(params)
	},
	async getList(context, {params, trigger}) {
		return fetchFeedContentsApi(params, trigger)
	},
	async getLogs(context, params) {
		return fetchFeedContentLogsApi(params)
	},
	async updateItem(context, body) {
		return updateFeedContentApi(body)
	},
	async fetchChannels(context) {
		return fetchChannelsApi()
	}
}