
import {
	getPlansApi,
	getSyncTimeApi,
	getLogsApi,
	getAccountsApi,
	updateBudgetApi,
	confirmBudgetPlansApi,
	refreshRawDataApi,
	exportRawDataApi,
	getConfigsApi,
	newConfigApi,
	updateConfigApi,
	getConfigApi,
	delConfigApi,
	activeConfigApi
} from '@/apis/budget.js'

export default {
	async getLogs(context, id) {
		return getLogsApi(id)
	},
	async getSyncTime(context, website) {
		return getSyncTimeApi(website)
	},
	async getList(context, {params, trigger}) {
		return getPlansApi(params, trigger)
	},
	async getAccounts(context, params) {
		return getAccountsApi({...params, websiteCode: params.website})
	},
	async updateBudget(context, body) {
		return updateBudgetApi(body)
	},
	async confirmBudgetPlans(context, ids) {
		return confirmBudgetPlansApi(ids)
	},
	async refreshRawData(context, params) {
		return refreshRawDataApi(params)
	},
	async exportRawData(context, {website, channel}) {
		return exportRawDataApi(website, channel)
	},
	async getConfigs(context, {params, trigger}) {
		return getConfigsApi(params, trigger)
	},
	async newConfig(context, body) {
		return newConfigApi(body)
	},
	async updateConfig(context, {id, body}) {
		return updateConfigApi(id, body)
	},
	async getConfig(context, id) {
		return getConfigApi(id)
	},
	async delConfig(context, id) {
		return delConfigApi(id)
	},
	async activeConfig(context, {id, active}) {
		return activeConfigApi(id, active)
	}
}