import {
	getListApi,
	exportApi
} from '@/apis/lookup-spu.js'

export default {
	async getList(context, body) {
		return getListApi(body)
	},
	async export(context, body) {
		return exportApi(body)
	}
}