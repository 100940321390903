import {
	fetchSourcesApi,
	fetchChartsApi,
	fetchDailyFormsApi,
	exportRecordsApi,
	fetchCostAndBalanceListApi,
	exportCostAndBalanceListApi,
	fetchSalesApi,
	exportSalesApi,
	fetchNewtestListApi,
	exportNewTestListApi,
} from '@/apis/dataset.js'
import {
	fetchChannelsApi
} from '@/apis/product.js'
import {
	getCategoriesApi
} from '@/apis/content.js'
import Moment from 'moment'
import { uuid } from 'vue-uuid';
export default {
	state: {
		sources: [
			'fb',
			'fb_all',
			'fb_dca'
		]
	},
	getters: {
		daliyLines() {
			return {
				0: {
					key: 'WEBSITE',
					value: '站点日线',
					label: 'Site'
				},
				1: {
					key: 'COUNTRY',
					value: '国家日线',
					label: 'Country'
				},
				2: {
					key: 'CHANNEL',
					value: '渠道日线',
					label: 'Channel'
				},
				3: {
					key: 'CATEGORY',
					value: '品类日线',
					label: 'Category'
				}
			}
		},
		comparisons() {
			return {
				0: {
					key: 'PREVIOUS',
					value: 'Previous'
				},
				1: {
					key: 'LAST_MONTH',
					value: 'Last Month'
				},
				2: {
					key: 'LAST_YEAR',
					value: 'Last Year'
				}
			}
		},
		others() {
			return {
				0: {
					key: 0,
					value: '非3日测新'
				},
				1: {
					key: 1,
					value: '3日基础测新'
				},
				2: {
					key: 2,
					value: '3日进阶测新'
				}
			}
		},
		timeGroups() {
			return [{
				label: '日',
				key:'DAY'
			}, {
				label: '周',
				key: 'WEEK',
				format: 'YYYY-W'
			}, {
				label: '月',
				key: 'MONTH',
				format: 'YYYY-M'
			}, {
				label: '季',
				key: 'SEASON',
				format: 'YYYY-Q'
			}]
		}
	},
	mutations: {
		updateSources(state, sources) {
			state.sources = sources
		}
	},
	actions: {
		async fetchSources({commit}) {
			return new Promise((resolve, reject) => {
				fetchSourcesApi()
				.then(data => {
					const sources = data.map(v => v.channel)
					commit('updateSources', sources)
					resolve(sources)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		async getAllChannels(context, parent = true) {
			return fetchChannelsApi(parent)
		},
		async getCategories(context) {
			return getCategoriesApi()
		},
		async fetchCharts(context, {params, signal}) {
			return fetchChartsApi(params, signal)
		},
		async fetchDailyForms(context, {params, trigger}) {
			return fetchDailyFormsApi(params, trigger)
		},
		async exportRecords(context, params) {
			return exportRecordsApi(params)
		},
		async fetchCostAndBalanceList(context, {params, trigger}) {
			return fetchCostAndBalanceListApi(params, trigger)
		},
		async exportCostAndBalanceList(context, params) {
			return exportCostAndBalanceListApi(params)
		},
		async fetchSales(context, {params, trigger}) {
			return fetchSalesApi(params, trigger)
		},
		async exportSales(context, params) {
			return exportSalesApi(params)
		},
		async fetchNewtestList(context, {params, trigger}) {
			return new Promise((resolve, reject) => {
				fetchNewtestListApi(params, trigger)
				.then(data => {
					const content = data.content.map(v => {
						const statistics = v.statistics || []
						if (statistics.length === 0) {
							return {
								...v,
								row_id: uuid.v4()
							}
						}
						const children = statistics.map(k => {
							return {
								...k,
								row_id: uuid.v4()
							}
						})
						const firstChild = children.shift()
						return {
							...v,
							...firstChild,
							children
						}
					})
					resolve({...data, content})
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		async exportNewtestList(context, params) {
			return exportNewTestListApi(params)
		}
	}
}
