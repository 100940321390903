import {
	createPagePixelApi,
	fetchPagePixelsApi,
	updatePagePixelApi,
	batchUpdatePagePixelApi,
	updatePagePixelDetailApi,
	getPagePixelApi
} from '@/apis/content.js'

export default {
	async newItem(context, body) {
		return createPagePixelApi(body)
	},
	async updateItem(context, { id, body }) {
		return updatePagePixelDetailApi(id, body)
	},
	async getList(context, { params, trigger }) {
		return new Promise((resolve, reject) => {
			fetchPagePixelsApi(params, trigger)
				.then(data => {
					const content = data.content.map(v => {
						const {
							id,
							pages
						} = v
						const groupId = id
						const children = pages.slice()
						const firstChild = children.shift()
						return {
							groupId,
							pages,
							...v,
							...firstChild,
							children
						}
					})
					resolve({
						...data,
						content
					})
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async activeItem(context, {id, active }) {
		return updatePagePixelApi(id, active)
	},
	async activeItems(context, { ids, active}) {
		return batchUpdatePagePixelApi(ids, active)
	},
	async getItem(context, id) {
		return getPagePixelApi(id)
	}
}