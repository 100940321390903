import {
	fetchCampaignsDataApi,
	exportCampaignsDataApi
} from '@/apis/dataset.js'

export default {
	async getList(context, {params, trigger}) {
		return fetchCampaignsDataApi(params, trigger)
	},
	async exportData(context, params) {
		return exportCampaignsDataApi(params)
	}
}