import {
	createAudienceApi,
	fetchAudiencesApi,
	activeAudiencesApi,
	inactiveAudiencesApi,
	updateAudienceDetailApi,
	getAudienceApi,
	//  custom audiences configurations api
	fetchInterestsApi,
	fetchCustomAudiencesApi,
	fetchAllPixelsApi,
	fetchAllCatalogsApi,
	newCustomAduienceApi,
	newLookAlikeAudienceApi,
	fetchAllPagesApi,
	fetchLookAlikeAudiencesApi,
	fetchAllAudiencesApi
} from '@/apis/content.js'
import {fetchJSONByApis} from '@/apis/index.js'

export default {
	async newItem(context, body) {
		return createAudienceApi(body)
	},
	async updateItem(context, { id, body }) {
		return updateAudienceDetailApi(id, body)
	},
	async getList(context, { params, trigger }) {
		return fetchAudiencesApi(params, trigger)
	},
	async activeItem(context, {id, active }) {
		if (active) {
			return activeAudiencesApi([id])
		}
		return inactiveAudiencesApi([id])
	},
	async activeItems(context, { ids, active}) {
		if (active) {
			return activeAudiencesApi(ids)
		}
		return inactiveAudiencesApi(ids)
	},
	async getItem(context, id) {
		return getAudienceApi(id)
	},
	async fetchInterests(context, {website, keyword}) {
		return fetchInterestsApi(website, keyword)
	},
	async getListOfCustomAudience(context, { params, trigger }) {
		return fetchCustomAudiencesApi(params, trigger)
	},
	async newItemOfCustomAudience(context, body) {
		return newCustomAduienceApi(body)
	},
	async updateItemOfCustomAudience(context, { id, body }) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({})
			}, 2000)
		})
	},
	async getListOfLookalikeAudience(context, { params, trigger }) {
		return fetchLookAlikeAudiencesApi(params, trigger)
	},
	async newItemOfLookalikeAudience(context, body) {
		return newLookAlikeAudienceApi(body)
	},
	async updateItemOfLookalikeAudience(context, { id, body }) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({})
			}, 2000)
		})
	},
	async fetchAllPixels(context, website) {
		return fetchAllPixelsApi(website)
	},
	async fetchAllCatalogs(context, website) {
		return fetchAllCatalogsApi(website)
	},
	async getAllSources(context, website) {
		return new Promise((resolve, reject) => {
			fetchJSONByApis(fetchAllPagesApi(website), fetchCustomAudiencesApi({website, page:1, size: 200}))
			.then(result => {
				const pages = Object.keys(result[0]).map(key => {
					return {
						sourceType: 'PAGE',
						sourceId: key,
						sourceName: result[0][key]
					}
				})
				const customAudiences = result[1].content.map(v => {
					return {
						sourceType: 'AUDIENCE',
						sourceId: v.id,
						sourceName: v.name
					}
				})
				resolve(pages.concat(customAudiences))
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	async fetchAllAudiences(context, website) {
		return fetchAllAudiencesApi(website)
	}
}