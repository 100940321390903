import {
	newItemApi,
	getListApi,
	activeItemApi,
	activeItemsApi,
	updateApi
} from '@/apis/google-accounts.js'

export default {
	async newItem(context, body) {
		return newItemApi(body)
	},
	async getList(context, { params, trigger }) {
		return getListApi(params, trigger)
	},
	async activeItem(context, { id, active }) {
		return activeItemApi(id, active)
	},
	async activeItems(context, { ids, active }) {
		return activeItemsApi(ids, active)
	},
	async updateItem(context, { id, body }) {
		return updateApi(id, body)
	}
}