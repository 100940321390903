import {
	newItemApi,
	getListApi,
	activeItemApi,
	activeItemsApi,
	updateItemApi
} from '@/apis/google-token.js'
export default {
	async newItem(context, body) {
		return newItemApi(body)
	},
	async getList(context, {params, trigger}) {
		return getListApi(params, trigger)
	},
	async activeItem(context, {id, active}) {
		return activeItemApi(id, active)
	},
	async activeItems(context, {ids, active}) {
		return activeItemsApi({ids}, active)
	},
	async updateItem(context, {id, body}) {
		return updateItemApi(id, body)
	}
}