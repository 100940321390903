import {
	getCountriesApi,
	getFullLanguagesApi,
	getShortLanguagesApi
} from '@/apis/google.js'

export default {
	// GG
	async getFullLanguages({ state }) {
		return new Promise((resolve, reject) => {
			getFullLanguagesApi()
				.then(content => {
					state._fullLanguages = content
					resolve()
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async getShortLanguages({ state }) {
		return new Promise((resolve, reject) => {
			getShortLanguagesApi()
				.then(content => {
					state._shortLanguages = content
					resolve()
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async getCountries({ state }) {
		return new Promise((resolve, reject) => {
			getCountriesApi()
				.then(content => {
					state._countries = content
					resolve()
				})
				.catch(err => {
					reject(err)
				})
		})
	}
}