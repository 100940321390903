export default {
	envs() {
		return [{
			key: 'TEST',
			value: '测试'
		}, {
			key: 'ONLINE',
			value: '正式'
		}]
	},
	taskTypes() {
		return [{
			key: 'MANUAL',
			value: '手动触发'
		}, {
			key: 'AUTO',
			value: '定时触发'
		}]
	},
	taskStates() {
		return [{
			key: 'QUEUED',
			value: '队列中'
		}, {
			key: 'IN_PROGRESS',
			value: '进行中'
		}, {
			key: 'SUCCESS',
			value: '已生成',
			status: 'success'
		}, {
			key: 'FAIL',
			value: '生成失败',
			status: 'exception'
		}]
	}
}
