import {
	createCountryLanguageApi,
	fetchCountryLanguagesApi,
	updateCountryLanguageApi,
	batchUpdateCountryLanguageApi,
	updateCountryLanguageDetailApi,
	getCountryLanguageApi
} from '@/apis/content.js'

export default {
	async newItem(context, body) {
		return createCountryLanguageApi(body)
	},
	async updateItem(context, { id, body }) {
		return updateCountryLanguageDetailApi(id, body)
	},
	async getList(context, { params, trigger }) {
		return fetchCountryLanguagesApi(params, trigger)
	},
	async activeItem(context, {id, active }) {
		return updateCountryLanguageApi(id, active)
	},
	async activeItems(context, { ids, active}) {
		return batchUpdateCountryLanguageApi(ids, active)
	},
	async getItem(context, id) {
		return getCountryLanguageApi(id)
	}
}