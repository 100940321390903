import {uuid} from 'vue-uuid'
import $Util from '@/common/util.js'
import {Message} from 'element-ui'
export default {
	addTask(state, {action, payload, title, dispatch, pagable = {current: 1, size: 50}, status = 'PENDING', content = null, showBadge = false, requestHandler = null, responseHandler = null, responseType = 'json'}) {
		Message.warning('已开始下载任务')
		state._tasks.unshift({
			status: status,
			action,
			payload, 
			title,
			dispatch, 
			pagable,
			requestHandler,
			responseHandler,
			id: uuid.v4(), 
			content: content || [{rows: [], title: 'Sheet1'}],
			createdAt: new Date().getTime(),
			responseHandler,
			responseType
		})
		if (typeof showBadge === 'boolean') {
			state.showBadge = showBadge
		}
	},
	removeTask(state, id) {
		const index = state._tasks.findIndex(v => v.id === id)
		if (index !== -1) {
			state._tasks.splice(index, 1)
		}
	},
	clearTasks(state) {
		state._tasks = []
	},
	exportXlsx(state, id) {
		const task = state._tasks.find(v => v.id === id)
		if (task) {
			const {responseType, content, title, data, filename} = task
			if (responseType === 'blob') {
				const blob = (window.URL || window.webkitURL).createObjectURL(new Blob([data]))
				const elt = document.createElement('a')
				elt.setAttribute('href', blob)
				elt.setAttribute('download', filename)
				document.body.appendChild(elt)
				elt.click()
				document.body.removeChild(elt)
				return
			}
			$Util.exportXlsx(task.content, task.title)
		}
	},
	toggleDrawer(state) {
		state.drawer = !state.drawer
		if (state.drawer) {
			state.showBadge = false
		}
	},
	inferShowBadge(state) {
		if (!state.drawer) {
			state.showBadge = true
		}
		console.log('inferShowBadge', state)
	}
}