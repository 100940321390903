import {
	fetchRecordsApi,
	rePublishApi,
	rePreviewAndPublishApi
} from '@/apis/record.js'
export default {
	state: {},
	getters: {},
	mutations: {},
	actions: {
		async fetchRecords(context, {params, trigger}) {
			return fetchRecordsApi(params, trigger)
		},
		async rePublish(context, {body, batch = false}) {
			return rePublishApi(body, batch)
		},
		async rePreviewAndPublish(context, {body, batch = false}) {
			return rePreviewAndPublishApi(body, batch)
		}
	}
}
