export default {
	channels() {
		return [{
			label: 'FACEBOOK',
			value: 'FACEBOOK'
		}, {
			label: 'GOOGLE',
			value: 'GOOGLE'
		}]
	},
	sources() {
		return [{
			label: '手动上传',
			value: 'MANUAL'
		}, {
			label: '脚本生成',
			value: 'SCRIPT'
		}]
	},
	directions() {
		return [{
			label: '上涨',
			value: 'INCREASE'
		}, {
			label: '下降',
			value: 'REDUCE'
		}]
	},
	bidTypes() {
		return [{
			label: 'ALL',
			value: 'ALL'
		},{
			label: 'LC',
			value: 'LC'
		},{
			label: 'NLC',
			value: 'NLC'
		}, {
			label: 'ROAS',
			value: 'ROAS'
		}]
	},
	syncStatusList() {
		return {'UNCONFIRMED':false,'QUEUED':true,'PENDING':false,'SUCCESS':true,'FAIL':false}
	},
	budgetRuleROIVariables() {
		return [
			'L7D_ROI', 
			'L3D_ROI', 
			'L1D_ROI',
		]
	},
	budgetRuleBudgetVariables() {
		return [
			'L1D_COST',
			'CURRENT_BUDGET', 
			'L3D_AVG_COST'
		]
	},
	budgetRuleFuncNames() {
		return [{
			key: 'MAX',
			value: '=MAX'
		}, {
			key: 'MIN',
			value: '=MIN'
		}, {
			key: 'EQ',
			value: '='
		}]
	}
}