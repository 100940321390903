import {
	createContextApi,
	fetchContextsApi,
	updateContextApi,
	batchUpdateContextApi,
	updateContextDetailApi,
	getContextApi
} from '@/apis/content.js'

export default {
	async newItem(context, body) {
		return createContextApi(body)
	},
	async updateItem(context, { id, body }) {
		return updateContextDetailApi(id, body)
	},
	async getList(context, { params, trigger }) {
		console.log('getList >>>' , params)
		return fetchContextsApi(params, trigger)
	},
	async activeItem(context, {id, active }) {
		return updateContextApi(id, active)
	},
	async activeItems(context, { ids, active}) {
		return batchUpdateContextApi(ids, active)
	},
	async getItem(context, id) {
		return getContextApi(id)
	}
}