import {
	fetchChannelsApi,
	fetchCategoriesAPi,
	fetchProductTemplatesApi,
	fetchAllChannelsApi
} from '@/apis/product.js'
import {
	fetchJSONByApis
} from '@/apis'
export default {
	state: {
		channels: [],
		allCategories: {}
	},
	getters: {
		categories(state) {
			const all = Object.values(state.allCategories).reduce((lhs, rhs) => lhs.concat(rhs), [])
			return Array.from(new Set(all)).sort()
		},
		adTypes() {
			return ['轮播', '单图', '视频']
		},
		publishStates() {
			return [{
				key: 'QUEUED',
				value: '队列中',
				style: ''
			}, {
				key: 'PUBLISHING',
				value: '发布中',
				style: 'primary'
			}, {
				key: 'SUCCESS',
				value: '发布成功',
				style: 'success'
			}, {
				key: 'FAIL',
				value: '发布失败',
				style: 'danger'
			}]
		}
	},
	mutations: {
		initChannel(state) {
			const channels = localStorage.getItem('ORIGIN_CHANNES')
			try {
				state.channels = JSON.parse(channels) || []
			} catch (e) {
				console.log(e)
			}
			const channel_category = localStorage.getItem('ORIGIN_CHANNEL_CATEGORY')
			try {
				state.allCategories = JSON.parse(channel_category) || {}
			} catch (e) {
				console.error(e)
			}
		},
		saveCahnnels(state, channels) {
			state.channels = channels
			localStorage.setItem('ORIGIN_CHANNES', JSON.stringify(state.channels))
		},
		saveCategories(state, allCategories) {
			state.allCategories = allCategories
			localStorage.setItem('ORIGIN_CHANNEL_CATEGORY', JSON.stringify(state.allCategories))
		}
	},
	actions: {
		async fetchChannels({
			state,
			commit,
			dispatch
		}) {
			return new Promise((reslove, reject) => {
				fetchChannelsApi()
					.then(data => {
						const keys = Object.keys(data)
						const channels = keys.map(key => {
							return {
								key,
								value: data[key]
							}
						})
						commit('saveCahnnels', channels)
						const apis = []
						channels.forEach(channel => {
							apis.push(dispatch('fetchCategories', channel.value))
						})
						fetchJSONByApis(...apis)
							.then(list => {
								const allCategories = {}
								list.forEach(({
									key,
									value
								}) => {
									allCategories[key] = value
								})
								commit('saveCategories', allCategories)
								reslove()
							})
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		async fetchCategories({
			state
		}, channel) {
			return new Promise((reslove, reject) => {
				fetchCategoriesAPi({
						channel
					})
					.then(categoires => {
						reslove({
							key: channel,
							value: categoires,
						})
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		async fetchCategoryList(context, {params, signal = null}) {
			return fetchCategoriesAPi(params, signal)
		},
		async fetchProductTemplates(context) {
			return fetchProductTemplatesApi()
		},
		async fetchAllChannels(context) {
			return fetchAllChannelsApi()
		}
	}
}
