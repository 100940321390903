import {
	fetchProductsApi,
	uploadToShopifyApi,
	fetchBlockProductsApi,
	delBlockProductsApi,
	fetchDistinctProductsApi,
	covertCarouselToSingleApi,
	previewAdsApi,
	publishAdsApi
} from '@/apis/product.js'
export default {
	async getList(context, {params, trigger}) {
		return fetchProductsApi(params, trigger)
	},
	async syncToShopify(context, body) {
		return uploadToShopifyApi(body)
	},
	async getBlockedList(context, params) {
		return fetchBlockProductsApi(params)
	},
	async getDistinctedList(context, {params, trigger}) {
		return fetchDistinctProductsApi(params, trigger)
	},
	async newSingleItem(context, body) {
		return covertCarouselToSingleApi(body)
	},
	async activeBlockedItems(context, body) {
		return delBlockProductsApi(body)
	},
	async preview(context, { body, single = false }) {
		return previewAdsApi(body, single)
	},
	async publish(context, {id, body}) {
		return publishAdsApi(id, body)
	},
}