import {
	fetchUsersApi,
	createUserApi,
	updateUserApi,
	getUserApi,
	loginApi,
	resetPwdApi,
	deleteUserApi
} from '@/apis/user'
import Cookies from 'js-cookie'
export default {
	state() {
		return {
			user: undefined
		}
	},
	getters: {
		
	},
	mutations: {
		initUser(state) {
			try {
				state.user = JSON.parse(localStorage.getItem('USER'))
			} catch (e) {
				console.error(e)
			}
		},
		saveUser(state, user) {
			state.user = user
			localStorage.setItem('USER', JSON.stringify(user))
			const {token} = user
			if (token) {
				localStorage.setItem('TOKEN', token)
				Cookies.set('ADS_TOKEN', token, { expires: 1 })
			}
		},
		removeUser(state) {
			localStorage.clear()
			Cookies.remove('ADS_TOKEN', { path: '' })
			state.user = undefined
		}
	},
	actions: {
		async login(context, body) {
			return loginApi(body)
		},
		async logout({commit, state}) {
			return new Promise((resolve, reject) => {
				commit('removeUser')
				commit('download-manager/clearTasks')
				resolve()
			})
		},
		async fetchUsers(context, {params, trigger}) {
			return fetchUsersApi(params, trigger)
		},
		async createUser(context, body) {
			return createUserApi(body)
		},
		async updateUser(context, {id, body}) {
			return updateUserApi(id, body)
		},
		async getUser(context, id) {
			return getUserApi(id)
		},
		async resetPwd(context, {id, body}) {
			return resetPwdApi(id, body)
		},
		async getUserSelf({state, commit}) {
			return new Promise((resolve, reject) => {
				const id = state.user && state.user.id
				if (id) {
					getUserApi(id)
					.then(user => {
						commit('saveUser', user)
					})
				} else {
					reject()
				}
			})
		},
		async deleteUser(context, id) {
			return deleteUserApi(id)
		}
	}
}