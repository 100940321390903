import {
	getListApi,
	exportApi
} from '@/apis/repeat.js'
export default {
	async getList(context, {params, trigger}) {
		return getListApi(params, trigger)
	},
	async export(context, params) {
		console.log(params)
		return exportApi(params)
	}
}