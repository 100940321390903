import {
	getListApi,
	saveItemApi,
	newItemApi,
	delItemsApi,
	previewApi,
	exportApi,
	publishApi
} from '@/apis/fb-video.js'

export default {
	async getList(context, { params, trigger }) {
		return getListApi(params, trigger)
	},
	async newItem(context, body) {
		console.log(body)
		return newItemApi(body)
	},
	async saveItem(context, {id, body}) {
		console.log(body)
		return saveItemApi(id, body)
	},
	async delItems(context, ids) {
		return delItemsApi(ids)
	},
	async preview(context, body) {
		return previewApi(body)
	},
	async export(context, body) {
		return exportApi(body)
	},
	async publish(context, {id, body}) {
		return publishApi(id, body)
	}
}