export default {
	operators() {
		return [{
			label: '打开',
			value: 'OPEN'
		}, {
			label: '关闭',
			value: 'CLOSE'
		}, {
			label: '删除',
			value: 'ARCHIVED'
		}]
	},
	operatorLevels() {
		return [{
			label: 'CAMPAIGN',
			value: 'CAMPAIGN'
		}, {
			label: 'AD_SET',
			value: 'AD_SET'
		}, {
			label: 'AD',
			value: 'AD'
		}]
	},
	crontabWeek() {
		return [{
			label: '周一',
			value: 2
		}, {
			label: '周二',
			value:3
		}, {
			label: '周三',
			value:4
		}, {
			label: '周四',
			value: 5
		}, {
			label: '周五',
			value: 6
		}, {
			label: '周六',
			value: 7
		},{
			label: '周日',
			value: 1
		}]
	},
	frequencies() {
		return [{
			label: '每天',
			value: 'day'
		}, {
			label: '每周',
			value: 'week'
		}]
	},
	crontabHours() {
		return Array(24).fill(0).map((v, i) => {
			if (i < 10) {
				return {
					label: '0' + i,
					value: '' + i
				}
			}
			return {
				label: '' + i,
				value: '' + i
			}
		})
	},
	crontabMinutes() {
		return [0,30].map((v) => {
			if (v < 10) {
				return {
					label: '0' + v,
					value: '' + v
				}
			}
			return {
				label: '' + v,
				value: '' + v
			}
		})
	},
	variables() {
		const val = ['Lnd_cost','Lnd_cpa','Lnd_roi','FB_Lnd_cpa','FB_Lnd_roi','create_days']
		const day = [0,1,3,7,14]
		const result = []
		for (var i = 0; i < val.length; i++) {
			const str = val[i]
			if (str.includes('n')) {
				for (var j = 0; j < day.length; j++) {
					result.push(str.replace('n', day[j]).toUpperCase())
				}
			} else {
				result.push(str.toUpperCase())
			}
		}
		return result
	}
}
