import {
	createCategoryUrlApi,
	fetchCategoryUrlsApi,
	updateCategoryUrlApi,
	batchUpdateCategoryUrlApi,
	updateCategoryUrlDetailApi,
	getCategoryUrlApi
} from '@/apis/content.js'

export default {
	async newItem(context, body) {
		return createCategoryUrlApi(body)
	},
	async updateItem(context, { id, body }) {
		return updateCategoryUrlDetailApi(id, body)
	},
	async getList(context, { params, trigger }) {
		return new Promise((resolve, reject) => {
			fetchCategoryUrlsApi(params, trigger)
				.then(data => {
					const content = data.content.map(v => {
						const {
							id,
							details,
							category
						} = v
						const children = details.slice()
						const firstChild = children.shift()
						return {
							groupId: id,
							categoryCode: category,
							details,
							...v,
							...firstChild,
							children
						}
					})
					resolve({
						...data,
						content
					})
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async activeItem(context, {id, active }) {
		return updateCategoryUrlApi(id, active)
	},
	async activeItems(context, { ids, active}) {
		return batchUpdateCategoryUrlApi(ids, active)
	},
	async getItem(context, id) {
		return getCategoryUrlApi(id)
	}
}