import {
	uploadApi,
	updateApi,
	delSpuApi,
	delSkuApi
} from '@/apis/shopify.js'

export default {
	async upload(context, body) {
		return uploadApi(body)
	},
	async update(context, body) {
		return updateApi(body)
	},
	async delSpu(context, body) {
		return delSpuApi(body)
	},
	async delSku(context, body) {
		return delSkuApi(body)
	},
}