import {
	getAdSwitchHisListApi,
	getAdSwtichConfigsApi,
	activeAdSwitchConfigApi,
	delAdSwitchConfigApi,
	newAdSwitchConfigApi,
	updateAdSwitchConfigApi
} from '@/apis/budget.js'

export default {
	async getList(context, {params, trigger}) {
		return getAdSwitchHisListApi(params, trigger)
	},
	async getConfigs(context, {params, trigger}) {
		return getAdSwtichConfigsApi(params, trigger)
	},
	async activeAdSwitchConfig(context, {id, active}) {
		return activeAdSwitchConfigApi(id, active)
	},
	async delAdSwitchConfig(context, id) {
		return delAdSwitchConfigApi(id)
	},
	async saveAdSwitchConfig(context, body) {
		const {id} = body
		if (id) {
			return updateAdSwitchConfigApi(id, body)
		}
		return newAdSwitchConfigApi(body)
	}
}