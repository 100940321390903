export default {
	recommendRules() {
		return [{
			label: 'Manual',
			value: 'MANUAL'
		}, {
			label: '热销款',
			value: 'HOT_SALE'
		}, {
			label: '潜力款',
			value: 'POTENTIAL'
		}, {
			label: '测新款',
			value: 'NEW_TEST'
		}, {
			label: '进阶测款',
			value: 'SUPER_TEST'
		}]
	},
	orderBys() {
		const props = [
			'tps.list_view_total',
			'tps.list_click_total', 
			'ctr_total', 
			'details_view_total', 
			'cart_add_total', 
			'tps.sales_total', 
			'tps.cr_total']
		const orders = {'ascending': 'asc', 'descending': 'desc'}
		return props.reduce((lhs, prop) => {
			Object.keys(orders).forEach(order => {
				lhs.push({
					order, 
					prop,
					key: `${prop} ${orders[order]}`
				})
			})
			return lhs
		}, [])
	}
}
