export default {
	publishTypes() {
		return [
			{
				label: 'Normal', 
				value: 'Normal'
			},
			{
				label: 'Multiple',
				value: 'Multiple'
			},
			{
				label: 'New Test',
				value: 'NEW_TEST'
			}
		]
	},
	actives() {
		return [
			{
				label: '开启',
				value: true
			},
			{
				label: '关闭',
				value: false
			}
		]
	},
	types() {
		return [
			{
				label: '轮播',
				value: 0
			},
			{
				label: '单图',
				value: 1
			}
		]
	},
	selectionRangeDays() {
		return [{
			value: 0,
			label: '当日'
		}, {
			value: 1,
			label: '昨日'
		}, {
			value: 3,
			label: '3天'
		}, {
			value: 7,
			label: '7天'
		}, {
			value: 14,
			label: '14天'
		}, {
			value: 28,
			label: '28天'
		}]
	},
	selectionRangeTypes() {
		return [{
			label: '热卖款',
			value: 'HOT_SALE',
			group: 0
		}, {
			label: '潜力款',
			value: 'POTENTIAL',
			group: 0
		}, {
			label: '测新款',
			value: 'NEW_TEST',
			group: 1
		}, {
			label: '进阶测款',
			value: 'SUPER_TEST',
			group: 2
		}]
	},
	groupRules() {
		return [{
			label: '随机搭配',
			value: 'RANDOM_GROUP'
		}, {
			label: '老带新',
			value: 'OLD_WITH_NEW_GROUP'
		}]
	},
	crontabWeek() {
		return [{
			label: '周一',
			value: 2
		}, {
			label: '周二',
			value:3
		}, {
			label: '周三',
			value:4
		}, {
			label: '周四',
			value: 5
		}, {
			label: '周五',
			value: 6
		}, {
			label: '周六',
			value: 7
		},{
			label: '周日',
			value: 1
		}]
	},
	frequencies() {
		return [{
			label: '每天',
			value: 'day'
		}, {
			label: '每周',
			value: 'week'
		}]
	},
	availableTempates() {
		return ['model31', 'model41']
	}
}
