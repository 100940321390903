import $conf from '../../common/config/config.js'
import {getDefaultNavBar} from '../../common/config/config.js'
export default {
	state: {
		navBar: {
			list: []
		}
	},
	getters: {

	},
	mutations: {
		initNavBar(state) {
			return state.navBar = getDefaultNavBar($conf.navBar)
		},
		createNavBar(state, menus) {
			state.navBar = getDefaultNavBar($conf.navBar)
		}
	},
	actions: {

	}
}