import $Util from '@/common/util.js'
export default {
	tipInfo() {
		return {
			users: [
				'用户数指客户邮箱数量，不包含测试单',
			],
			repurchaseRate: [
				'复购率=复购用户数/用户数'
			],
			repurchasePercent: [
				'复购贡献度=渠道复购订单数/复购订单数，各渠道的复购贡献度总计100%'
			],
			repurchaseGmvPercent: [
				'复购GMV占总GMV比例=复购GMV/总GMV'
			],
			repeatDays: [
				"7天复购率：用户第二次下单距离上一次下单在7天内才计作复购",
				"30天复购率：用户第二次下单距离上一次下单在30天内才计作复购",
				"45天复购率：用户第二次下单距离上一次下单在45天内才计作复购",
				"60天复购率：用户第二次下单距离上一次下单在60天内才计作复购",
				"不限时间复购率：用户第二次下单就计作复购"
			],
			siteCross: [
				"单站点统计：同一用户在同一站点多次购买，计作复购",
				"跨站点统计：同一用户在不同站点多次购买，计作复购"
			]
		}
	},
	repeatDays() {
		return [
			{
				key: "7",
				label: '7天复购率'
			},
			{
				key: "30",
				label: '30天复购率'
			},
			{
				key: "45",
				label: '45天复购率'
			},
			{
				key: "60",
				label: '60天复购率'
			},
			{
				key: "2000",
				label: '不限'
			}
		]
	},
	siteCross() {
		return [{
			key: "false",
			label: '单站点'
		}, {
			key: "true",
			label: '跨站点'
		}]
	},
	orderBy() {
		const columns = [
			'website',
			'orderCount',
			'gmv',
		]
		const sorts = [
			'asc',
			'desc'
		]
		return $Util.cartesianProductOf(columns, sorts).map(v => v.join(','))
	}
}
