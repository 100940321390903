import {
	fetchSingleProductsApi,
	previewAdsApi,
	publishAdsApi
} from '@/apis/product.js'
export default {
	async getList(context, { params, trigger }) {
		return fetchSingleProductsApi(params, trigger)
	},
	async preview(context, { body, single = true }) {
		return previewAdsApi(body, single)
	},
	async publish(context, {id, body}) {
		return publishAdsApi(id, body)
	},
}