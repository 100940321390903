export default {
	rules() {
		return [{
			key: 'INCLUDE',
			value: '商品添加'
		}, {
			key: 'EXCLUDE',
			value: '商品排除'
		}]
	},
	states() {
		return [{
			key: 'DRAFT',
			value: '草稿',
			status: ''
		}, {
			key: 'TEST',
			value: '测试',
			status: 'primary'
		}, {
			key: 'ONLINE',
			value: '正式',
			status: 'success'
		}, {
			key: 'CLOSE',
			value: '关闭',
			status: 'danger'
		}]
	},
	contentTypes() {
		return [{
			key: 'CATEGORY',
			value: '品类'
		}, {
			key: 'SPU',
			value: '商品spu'
		}]
	}
}
