import {
	fetchActiveFeedsApi,
	newTagApi,
	updateTagApi,
	delTagApi,
	fetchTagsApi,
	fetchChannelsApi,
	fetchGoogleProductCategoriesApi,
	manageTagApi,
	fetchTagLogsApi
} from '@/apis/feed.js'
export default {
	async fetchActiveFeeds(context, params) {
		return fetchActiveFeedsApi(params)
	},
	async getList(context, {params, trigger}) {
		return fetchTagsApi(params, trigger)
	},
	async saveTag(context, body) {
		const {id} = body
		if (id) {
			return updateTagApi(id, body)
		}
		return newTagApi(body)
	},
	async delTag(context, id) {
		return delTagApi(id)
	},
	async fetchChannels(context) {
		return fetchChannelsApi()
	},
	async getCategories(context, params) {
		return fetchGoogleProductCategoriesApi(params)
	},
	async manageTag(context, {id, action}) {
		return manageTagApi(id, action)
	},
	async getLogs(context, id) {
		return fetchTagLogsApi(id)
	}
}