import {
	getListApi,
	newAdStrategyConfigApi,
	updateAdStrategyConfigApi,
	activeAdStrategyConfigApi,
	getLogsApi,
	getRecordsApi,
	getDetailsApi,
	remakePicsApi
} from '@/apis/ad-strategy.js'

export default {
	async getList(context, {params, trigger}) {
		return getListApi(params, trigger)
	},
	async saveAdStrategyConfig(context, body) {
		const {id} = body
		return id ? updateAdStrategyConfigApi(id, body) : newAdStrategyConfigApi(body)
	},
	async activeAdStrategyConfig(context, {id, active}) {
		return activeAdStrategyConfigApi(id, active)
	},
	async getLogs(context, id) {
		return getLogsApi(id)
	},
	async getRecords(context, {params, trigger}) {
		return getRecordsApi(params, trigger)
	},
	async getDetails(context, id) {
		return getDetailsApi(id)
	},
	async remakePics(context, id) {
		return remakePicsApi(id)
	}
}