export default {
	tagRuleTypes() {
		return [{
			key: 'SPECIFIED',
			value: '指定品类/商品'
		}, {
			key: 'CONDITIONAL',
			value: '条件规则'
		}]
	},
	states() {
		return [{
			key: 'DRAFT',
			value: '草稿',
			status: ''
		}, {
			key: 'TEST',
			value: '测试',
			status: 'primary'
		}, {
			key: 'ONLINE',
			value: '正式',
			status: 'success'
		}, {
			key: 'CLOSE',
			value: '关闭',
			status: 'danger'
		}]
	},
	conditions() {
		return [{
			label: '所有',
			value: 'AND'
		}, {
			label: '任一',
			value: 'OR'
		}]
	},
	columnNames() {
		return [
			'custom_label_0',
			'custom_label_1',
			'custom_label_2',
			'custom_label_3',
			'custom_label_4',
			'ads_grouping',
			'ads_labels'
		]
	}
}
