export default {
	runLoop({getters, commit}) {
		setInterval(() => {
			getters.tasks.forEach(t => {
				const {status, action, payload, id, dispatch, pagable, requestHandler, responseHandler, responseType, title} = t
				if (status === 'PENDING') {
					t.status = 'RUNNING'
					const params = requestHandler ? requestHandler(payload, pagable) : {...payload, ...pagable}
					dispatch(action, params)
					.then(res => {
						// ResponseType -> Blob
						if (responseType == 'blob') {
							const { data, status, headers } = res
							if (status === 200 && typeof data === 'object') {
								const content_disposition = headers['content-disposition']
								const ext = content_disposition.match(/(\.[\w]+)/g) || ['.zip']
								t.data = data
								t.filename = title + ext[0]
								t.status = 'FINISH'
							} else {
								t.status = 'ERROR'
							}
							commit('inferShowBadge')
							return
						}
						// ResponseType -> JSON
						const data = responseHandler ? responseHandler(res) : res
						const {totalPages, content} = data
						t.content[0].rows = t.content[0].rows.concat(content)
						if (pagable.page < totalPages) {
							pagable.page += 1
							t.status = 'PENDING'
						} else {
							t.status = 'FINISH'
							commit('inferShowBadge')
						}
					})
					.catch(err => {
						t.status = 'ERROR'
						commit('inferShowBadge')
					})
				}
			})
		}, 1000)
	}
}