export default {
	conditions() {
		return [{
			label: '所有',
			key: 'AND',
			symbol: 'AND'
		}, {
			label: '任一',
			key: 'OR',
			symbol: 'OR'
		}]
	},
	sources() {
		return [{
			label: 'Website Pixel',
			key: 'PIXEL'
		}, {
			label: 'Catalog',
			key: 'CATALOG'
		}]
	},
	events() {
		return [{
			label: 'CustomizeProduct',
			key: 'CustomizeProduct'
		}, {
			label: 'ViewContent',
			key: 'ViewContent'
		}, {
			label: 'AddToCart',
			key: 'AddToCart'
		}, {
			label: 'InitiateCheckout',
			key: 'InitiateCheckout'
		}, {
			label: 'Purchase',
			key: 'Purchase'
		}]
	},
	genders() {
		return [{
			label: '全部',
			key: 0
		}, {
			label: '男',
			key: 1
		}, {
			label: '女',
			key: 2
		}]
	}
}