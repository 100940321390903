import {
	fetchSitesApi,
	fetchSiteApi,
	delSiteApi,
	updateSiteApi,
	createSiteApi
} from '@/apis/settings.js'
export default {
	state: {},
	getters: {},
	actions: {
		async fetchSites(context, {params, trigger}) {
			const content = Array(20).fill('').map((v, i) => {
				return {
					id: i,
					project: Math.floor(Math.random() * 2) === 0 ? 'Clothe' : '3C',
					domain: `www.hekka${i}.com`,
					site: `HK${i}`,
					gaId: `23123123_${i}`,
					sys: Math.floor(Math.random() * 2) === 0 ? '自建' : 'Shopify',
					name: Math.floor(Math.random() * 2) === 0  ? '' : `店铺${i}`,
					token: Math.floor(Math.random() * 2) === 0  ? '' : '12sdjfdsfjdskfj',
					status: ''
				}
			})
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve({
						total: 20,
						content
					})
				}, 2000)
			})
			// return fetchSitesApi(params, trigger)
		},
		async fetchSite(context, id) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve({
						id: 1,
						project: Math.floor(Math.random() * 2) === 0 ? 'Clothe' : '3C',
						domain: `www.hekka.com`,
						site: `HK`,
						gaId: `23123123`,
						sys: Math.floor(Math.random() * 2) === 0 ? '自建' : 'Shopify',
						name: Math.floor(Math.random() * 2) === 0  ? '' : `店铺`,
						token: Math.floor(Math.random() * 2) === 0  ? '' : '12sdjfdsfjdskfj',
						status: ''
					})
				}, 2000)
			})
			// return fetchSiteApi(id)
		},
		async delSite(context, id) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve()
				}, 2000)
			})
			// return delSiteApi(id)
		},
		async updateSite(context, {id, body}) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve({
						id: 1,
						project: Math.floor(Math.random() * 2) === 0 ? 'Clothe' : '3C',
						domain: `www.hekka.com`,
						site: `HK`,
						gaId: `23123123`,
						sys: Math.floor(Math.random() * 2) === 0 ? '自建' : 'Shopify',
						name: Math.floor(Math.random() * 2) === 0  ? '' : `店铺`,
						token: Math.floor(Math.random() * 2) === 0  ? '' : '12sdjfdsfjdskfj',
						status: ''
					})
				}, 2000)
			})
			// return updateSiteApi(id, body)
		},
		async createSite(context, body) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve()
				}, 2000)
			})
			// return updateSiteApi(body)
		}
	}
}