import Vue from 'vue';
import Vuex from 'vuex';
// 引入模块
import menu from './modules/menu.js'
import product from './modules/product.js'
import content from './modules/content.js'
import user from './modules/user.js'
import record from './modules/record.js'
import dataset from './modules/dataset.js'
import FbVideoAds from './modules/fb-video-ads/index.js'
import Shopify from './modules/shopify/index.js'
import GoogleAds from './modules/google-ads/index.js'
import GoogleAcconts from './modules/google-accounts/index.js'
import Google from './modules/google/index.js'
import GoogleContentsUDA from './modules/google-contents-uda/index.js'
import GoogleContentsLandingLink from './modules/google-contents-landinglink/index.js'
import GoogleContentsLanguage from './modules/google-contents-language/index.js'
import FbToken from './modules/fb-token/index.js'
import GoogleToken from './modules/google-token/index.js'
import FbCarouselAds from './modules/fb-carousel-ads/index.js'
import FbSingleAds from './modules/fb-single-ads/index.js'
import FbAcconts from './modules/fb-accounts/index.js'
import settings from './modules/settings.js'
import FbContentsContext from './modules/fb-contents-context/index.js'
import FbContentsPixel from './modules/fb-contents-pixel/index.js'
import FbContentsLandinglink from './modules/fb-contents-landinglink/index.js'
import FbContentsLanguage from './modules/fb-contents-language/index.js'
import FbContentsAudience from './modules/fb-contents-audience/index.js'
import FbContentsCountry from './modules/fb-contents-country/index.js'
import FeedsContentProduct from './modules/feeds-content-product/index.js'
import FeedsQueue from './modules/feeds-queue/index.js'
import CampaignReport from './modules/campaign-report/index.js'
import LTVReport from './modules/ltv-report/index.js'
import Repurchase from './modules/repurchase/index.js'
import LookupSpu from './modules/lookup-spu/index.js'
import DownloadManager from './modules/download-manager/index.js'
import BudgetPlan from './modules/budget-plan/index.js'
import AdSwitch from './modules/ad-switch/index.js'
import FeedsContentTag from './modules/feeds-content-tag/index.js'
import AdStrategy from './modules/ad-strategy/index.js'
import SuperTest from './modules/super-test/index.js'
import JSCX from './modules/jscx/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		menu,
		product,
		content,
		user,
		record,
		dataset,
		'fb-video-ads': FbVideoAds,
		'shopify': Shopify,
		'google-ads': GoogleAds,
		'google-accounts': GoogleAcconts,
		'google': Google,
		'google-contents-uda': GoogleContentsUDA,
		'google-contents-landinglink': GoogleContentsLandingLink,
		'google-contents-language' : GoogleContentsLanguage,
		'fb-token': FbToken,
		'google-token': GoogleToken,
		'fb-carousel-ads': FbCarouselAds,
		'fb-single-ads': FbSingleAds,
		'fb-accounts': FbAcconts,
		'fb-contents-context': FbContentsContext,
		'fb-contents-pixel': FbContentsPixel,
		'fb-contents-landinglink': FbContentsLandinglink,
		'fb-contents-language': FbContentsLanguage,
		'fb-contents-audience': FbContentsAudience,
		'fb-contents-country': FbContentsCountry,
		'feeds-content-product': FeedsContentProduct,
		'feeds-content-tag': FeedsContentTag,
		'feeds-queue': FeedsQueue,
		'campaign-report': CampaignReport,
		'ltv-report': LTVReport,
		'repurchase': Repurchase,
		'lookup-spu': LookupSpu,
		'download-manager': DownloadManager,
		'budget-plan': BudgetPlan,
		'budget-switch': AdSwitch,
		'ad-strategy': AdStrategy,
		'super-test': SuperTest,
		'jscx': JSCX,
		settings
	}
})