import {
	getCountriesApi,
	getSiteCountriesApi,
	newCountriesApi,
	renewCountriesApi,
	getSiteAllCountriesApi
} from '@/apis/content.js'

export default {
	async getCountries(context) {
		return getCountriesApi()
	},
	async getSiteCountries(context, website) {
		return getSiteCountriesApi(website)
	},
	async newCountries(context, body) {
		return newCountriesApi(body)
	},
	async renewCountries(context, body) {
		return renewCountriesApi(body)
	},
	async getSiteAllCountries(context, website) {
		return getSiteAllCountriesApi(website)
	}
}