import {
	newItemApi,
	updateItemApi,
	getListApi,
	delItemApi,
	delItemsApi,
	getItemApi
} from '@/apis/google-content-uda.js'

export default {
	// UDA模板
	async newItem(context, body) {
		return newItemApi(body)
	},
	async updateItem(context, { id, body }) {
		return updateItemApi(id, body)
	},
	async getList(context, { params, trigger }) {
		return getListApi(params, trigger)
	},
	async delItem(context, id) {
		return delItemApi(id)
	},
	async delItems(context, body) {
		return delItemsApi(body)
	},
	async getItem(context, id) {
		return getItemApi(id)
	}
}