import {
	fetchProductsApi,
	fetchDistinctProductsApi,
	fetchAdImgGroupsApi,
	previewAdsApi,
	publishAdsApi,
	createAdImgApi
} from '@/apis/google-ads.js'

export default {
	async getList(context, { params, trigger }) {
		return fetchProductsApi(params, trigger)
	},
	async getListByDistincted(context, { params, trigger }) {
		return fetchDistinctProductsApi(params, trigger)
	},
	async getListFromAdImage(context, { params, trigger }) {
		return fetchAdImgGroupsApi(params, trigger)
	},
	async preview(context, { body }) {
		return previewAdsApi(body)
	},
	async publish(context, id) {
		return publishAdsApi(id)
	},
	async newAdImage(context, ids) {
		return createAdImgApi(ids)
	}
}