export default {
	sources() {
		return [{
			key: 0,
			label: '新款'
		}, {
			key: 1,
			label: '潜力'
		}, {
			key:  2,
			label: '手动'
		}]
	},
	listOfStatus() {
		return [{
			key:0,
			label: '未开始'
		}, {
			key:1,
			label: '成功'
		}, {
			key:2,
			label: '进行中'
		}, {
			key: 8,
			label: '已删除'
		}, {
			key:  9,
			label: '失败'
		}]
	}
}